<template>
    <div>
        <div class="modal_header">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                            {{ $t('message.show_audit') }} 
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                            <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="row">
            <div class="col-12 mt-2">
                <div class="modal-body">
                    <el-form ref="form" status-icon :model="form" :rules="rules" class="aticler_m">
                        <el-row :gutter="20">
                            <el-col :sm="12">
                                {{ $t('message.doctor_0') }}: {{ model.user ? model.user.name + ' ' + model.user.surname : " - " }}
                            </el-col>
                            <el-col :sm="12">
                                {{ $t('message.conclusions') }}: #{{ model.conclusion ? model.conclusion.id : ' - '}}
                            </el-col>
                        </el-row>
                            <el-divider></el-divider>
                            <div v-if="model.conclusion" v-html="model.conclusion.content"></div>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters,mapActions} from 'vuex'
    import form from '@/utils/mixins/form';
    export default {
        mixins: [form],
        props: {
            reloadModel: {type: Boolean, default: true}
        },
        data(){
            return {}
        },
        watch: {
            reloadModel: {
                handler: function () {
                    if (this.reloadModel){
                        this.loadModel()
                    }
                },
                deep: true
            },
        },
        created(){},
        async mounted(){
        },
        computed: {
            ...mapGetters({
                rules: 'printAuditable/rules',
                model: 'printAuditable/model',
                columns: 'printAuditable/columns',
            })
        },
        methods:{
            ...mapActions({
            }),
            loadModel(){
                this.form = JSON.parse( JSON.stringify( this.model ));
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.$emit('c-close',{drawer: 'drawerUpdate'});
            },
        }
    }

</script>
